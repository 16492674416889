import React from 'react'
import {
    BookingDateRangeFilter,
    FieldDateInput,
    FilterPopup
    // Form
} from '../../components';
import DateInput from '../../components/FieldDateInput/DateInput'
import { stringifyDateToISO8601 } from '../../util/dates';
import { Form } from 'react-final-form';


import css from './SearchPage.module.css'
const options = { year: 'numeric', month: 'long', day: 'numeric' };

const DatePicker = props => {
    const {
        filter,
        onChange,
        currentCategory,
        intl,
        config,
        rest,
        className,
        rootClassName
    } = props

    const onChangeDate = (date) => {
        const d = stringifyDateToISO8601(date)
        onChange('dates', d + "," + d)
    }

    const convertDateToreadingFormat = (d) => {
        let d1 = d.split(',')[0];
        return new Date(d1).toLocaleDateString('fr', options)
    }

    if (currentCategory !== 'garden_share') {
        return <BookingDateRangeFilter
            className={(className == undefined ? "" : className) + " datePickerClass"}
            rootClassName={rootClassName}
            id={"10"}
            initialValues={{ date: new Date() }}
            label={filter.dates ? convertDateToreadingFormat(filter.dates) : "Quand ?"}
            queryParamNames={["dates"]}
            onSubmit={(e) => { console.log("cahnges date", className); onChange("dates", e.dates); }}
            onFocus={(e) => console.log('euhuiheiu', e)}
            onChange={(e) => console.log("ooooooo")}
            {...config}
            {...rest}
        />
    }
    else {
        return <></>;
    }

    /*
        return <Form onSubmit={() => console.log("----------SUBMT-----------")}>
            {props => (
                <FieldDateInput
                    name="bookingDates"
                    id="bookingDates"
                    className={css.singleDatePicker}
                    placeholderText={"Quand ?"}
                    // timeSlots={timeSlots}
                    // // unitType={unitType}
                    // format={identity}
                    // disabled={isOwnListing}
                    // isOwnListing={isOwnListing}
                    onFocus={(e) => console.log('euhuiheiu', e)}
                    onChange={(e) => onChangeDate(e.date)}
    
                />
            )}
        </Form>
    
    
        return <DateInput
            name="bookingDates"
            id="bookingDates"
            className={css.bookingDates}
            placeholderText={filter.dates ? filter.dates.split(',')[0] : "Quand ?"}
            onChange={(e) => onChangeDate(e.date)}
            onFocus={(focus) => console.log(focus)}
            onBlur={(blur) => console.log(blur)}
            label={filter.date}
        />
        */
}

export default DatePicker;
