import React from 'react'
import { FormattedMessage } from '../../util/reactIntl';
import {Divider} from '@material-ui/core'
import { getListingsById } from '../../ducks/marketplaceData.duck';
import {
    SearchResultsPanel,
  } from '../../components';

import css from './SearchPage.module.css';

const SectionNoResult = props => {
    const {defaultListings, urlQueryParams, inProgress} = props

    React.useEffect(() => {
        async function fetchData() {
            // You can await here
            if(urlQueryParams.pub_category)
            await props.onSearchSimilarListing({pub_category: urlQueryParams.pub_category});
        }
        fetchData();
    }, []);

    return <div className={css.SectionNoResultContainer}>
        <div className={css.SectionNoResultMessageWrapper}>
            <p><FormattedMessage id="SectionNoResult.message" /></p>
        </div>
        <Divider />
        <h3>ANNONCES SIMILAIRES</h3>
        {
            inProgress ? <p>Chargement...</p> :
            defaultListings && defaultListings.length > 0 ? 
                <SearchResultsPanel
                    className={css.searchListingsPanel}
                    listings={defaultListings}
                    pagination={ null}
                />
            : null 
        }
    </div>
}

export default SectionNoResult;