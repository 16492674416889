import React from 'react';
import {
  Button
} from '../../components';

// CSS
import css from './SearchPage.module.css'


const FilterButtonGroup = (props) => {
  const IsActive = props.active === props.type


  const handleSearch = (key) => {
    props.handleChange(props.queryName, key)
  }

  const dynamicStyle = (IsActive) => { return { backgroundColor: IsActive ? "#e12c42" : "#f3f3f3", color: IsActive ? "white" : "#4a4a4a" } }

  return <div>
    <Button className={css.buttonFilter} style={dynamicStyle(IsActive)} onClick={() => handleSearch(props.type)} dangerouslySetInnerHTML={{ __html: props.title }}>

    </Button>
  </div>
}

export default FilterButtonGroup;
