import React from 'react'
import { FormattedMessage } from '../../util/reactIntl';
import {
    FilterEvent,
    FilterAmbiance,
    FilterSortBy,
    FilterMaxPeople,
    PrimaryButton,
    BookingDateRangeFilter,
    FieldDateInput
} from '../../components';
import FilterButtonGroup from './FilterButtonGroup'
import { Grid } from '@material-ui/core';
import css from './SearchPage.module.css'
import DatePicker from './DatePicker'

const MobileFilter = props => {
    const {
        categories,
        filter,
        onChange,
        urlQueryParams,
        history,
        setOpenModal,
        currentCategory,
        classifieldsOptions,
        gardenTypeOptions,
        onCategoryChange,
        intl,
        config,
        rest,
        locationInput,
        isOpen
    } = props


    if (!isOpen) {
        return null
    }

    const renderValidateButton = <div className={css.validateButton}>
        <PrimaryButton onClick={() => setOpenModal(false)}>
            Valider
        </PrimaryButton>
    </div>

    const renderTitle = <h2 style={{ textAlign: "center" }}>Filtres</h2>

    if (currentCategory === "garden_share") {
        return <div style={{ padding: 10 }}>
            {renderTitle}

            <h3>Type de jardin</h3>
            <Grid container justify="center" spacing={2} style={{ padding: 10 }}>
                {
                    gardenTypeOptions.map((item, i) =>
                        <Grid item key={i} xs={6} >
                            <FilterButtonGroup
                                key={i}
                                title={intl.formatMessage({ id: `FieldCheckbox2Lines.${[item.key]}` })}
                                type={item.key}
                                queryName="pub_garden_type"
                                active={filter.pub_garden_type}
                                handleChange={onChange}
                            />
                        </Grid>
                    )
                }
            </Grid>

            {renderValidateButton}
        </div>
    }

    return <div style={{ padding: 10 }}>
        {renderTitle}

        <Grid container spacing={2}>
            <Grid item xs={12}>
                <p>Nombre de personnes</p>
                <FilterMaxPeople
                    history={history}
                    isMobile={true}
                    intl={intl}
                    maxPeople={filter.pub_capacity_max_people}
                    handleChange={onChange}
                />
            </Grid>
            {
                currentCategory !== "tinyhouse" &&
                <Grid item xs={12}>
                    <p>Evénement</p>
                    <FilterEvent
                        history={history}
                        isMobile={true}
                        intl={intl}
                        event={filter.pub_events}
                        handleChange={onChange}
                    />
                </Grid>
            }
            <Grid item xs={12}>
                <p>Ambiance</p>
                <FilterAmbiance
                    isMobile={true}
                    intl={intl}
                    ambiance={filter.pub_ambiances}
                    handleChange={onChange}
                    category={urlQueryParams.pub_category}
                />
            </Grid>
            <Grid item xs={12}>
                <p>Classé par</p>
                <FilterSortBy
                    isMobile={true}
                    intl={intl}
                    history={history}
                    initValue={filter.sort}
                    handleChange={onChange}
                />
            </Grid>

        </Grid>

        {renderValidateButton}

    </div>
}

export default MobileFilter;