import React from 'react'
import { Grid, CircularProgress } from '@material-ui/core'
import { FormattedMessage } from '../../util/reactIntl';
import routeConfiguration from '../../routeConfiguration';
import { createResourceLocatorString } from '../../util/routes';
import { types as sdkTypes } from '../../util/sdkLoader';
import FilterButtonGroup from './FilterButtonGroup'
import { findOptionsForSelectFilter } from '../../util/search';
import {
    FilterEvent,
    FilterAmbiance,
    FilterSortBy,
    Filterm2,
    FilterMaxPeople,
    PrimaryButton,
    SecondaryButton,
    LocationAutocompleteInputField,
    ModalInMobile
} from '../../components';
import { Form } from 'react-final-form';
import { Switch, withStyles, Button } from '@material-ui/core';
import css from './SearchPage.module.css'
import MobileFilter from './MobileFilter'
import DatePicker from './DatePicker'
import FilterListIcon from '@material-ui/icons/FilterList';

import { ReactComponent as GardenRentIcon } from './icons/garden_rent.svg'
import { ReactComponent as GardenShareIcon } from './icons/garden_share.svg'
import { ReactComponent as TinyhouseIcon } from './icons/tinyhouse.svg'


const identity = v => v;
const { LatLng, LatLngBounds } = sdkTypes;
const MODAL_BREAKPOINT = 1038
const INIT_BOUNDS = new LatLngBounds(new LatLng(51.148409399929, 9.66015649291518), new LatLng(41.3108229, -5.24227249992918));

const PurpleSwitch = withStyles({
    switchBase: {
        color: "#fff",
        '&$checked': {
            color: "#e12c42",
        },
        '&$checked + $track': {
            backgroundColor: "#e12c42",
        },
    },
    checked: {},
    track: {
        backgroundColor: "#b7b7b7"
    },
})(Switch);

const formatDate = (values) => {
    let splited = values.split(',')
}

const renderIcon = (key) => {
    switch (key) {
        case "garden_rent":
            return <GardenRentIcon style={{ height: 60 }} />
        case "garden_share":
            return <GardenShareIcon style={{ height: 60 }} />
        case "tinyhouse":
            return <TinyhouseIcon style={{ height: 60 }} />
        default:
            return <GardenRentIcon style={{ height: 60 }} />
    }
}

const NewSearchPage = (props) => {
    const {
        idPrefix,
        filterConfig,
        urlQueryParams,
        // initialValues,
        getHandleChangedValueFn,
        listing,
        searchInProgress,
        intl,
        history,
        mapModal,
        handleMapModal,
        isMobile,
        ...rest
    } = props;
    const [currentCategory, setCurrentCategory] = React.useState(urlQueryParams.pub_category)
    const [openModal, setOpenModal] = React.useState(false)

    const [filter, setFilter] = React.useState({
        pub_events: null,
        pub_ambiances: null,
        sort: null,
        pub_garden_type: urlQueryParams.pub_garden_type || null,
        dates: null,
        pub_capacity_max_people: null,
        pub_classifields_type: null,
    })
    const [hasFilter, setHasFilter] = React.useState(false)


    const categories = ["garden_rent", "garden_share", "tinyhouse"]
    const { config } = filterConfig;

    const handleResetMobile = () => {
        onCategoryChange(currentCategory)
        setOpenModal(false)
    }

    const onChange = (type, value) => {

        if (!value) {
            delete urlQueryParams[type]
            setFilter({ ...filter, [type]: null })
        } else {
            setHasFilter(true)
            urlQueryParams[type] = value
            if (type === 'pub_capacity_max_people') {
                urlQueryParams['sort'] = "-" + type
            } else {
                delete urlQueryParams['pub_capacity_max_people']
            }
            setFilter({ ...filter, [type]: value })
        }

        history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, urlQueryParams));
    }

    const handleSelectedPlace = (e) => {
        if (e) {
            if (e.selectedPlace) {
                setHasFilter(true)
                const neLat = e.selectedPlace.bounds.ne.lat
                const swLat = e.selectedPlace.bounds.sw.lat
                const neLng = e.selectedPlace.bounds.ne.lng
                const swLng = e.selectedPlace.bounds.sw.lng
                urlQueryParams['address'] = e.search
                urlQueryParams['bounds'] = new LatLngBounds(new LatLng(neLat, neLng), new LatLng(swLat, swLng));
                history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, urlQueryParams));
            }
        }
    }

    const onCategoryChange = (item) => {
        setCurrentCategory(item)
        setFilter({
            pub_events: null,
            pub_ambiances: null,
            sort: null,
            pub_garden_type: null,
            dates: null,
            pub_capacity_max_people: null,
            pub_classifields_type: null,
        })

        let query = {
            address: "France",
            mapSearch: true,
            bounds: INIT_BOUNDS,
            pub_category: item
        }
        setHasFilter(false)
        history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, query));
    }

    const classifieldsOptions = findOptionsForSelectFilter('classifields_type', filterConfig.custom.filters);
    const gardenTypeOptions = findOptionsForSelectFilter('garden_type', filterConfig.custom.filters);

    /*["garden_rent", "garden_share", "tinyhouse"]*/



    return <div className={css.root}>

        <Grid container spacing={!isMobile ? 5 : 0} >
            <Grid item xs={12} className={css.hideDesktop}>
                <>{
                    <>
                        <div className={css.categoryFilterForMobile} >
                            <PrimaryButton xs={4} key="garden_rent" className={css.mobileButtonCategory} onClick={() => onCategoryChange("garden_rent")}>
                                <div>
                                    <div style={{ textAlign: "center", display: "flex", flexDirection: "column" }}>
                                        <GardenRentIcon style={{ height: 40, marginBottom: 10 }} />
                                        <FormattedMessage id={`FieldCheckbox.garden_rent`} />
                                        <div className={currentCategory === "garden_rent" ? css.selectedCategoryTextForMobile : css.categoryTextForMobile} >
                                        </div>
                                    </div>

                                </div>
                            </PrimaryButton>
                            <PrimaryButton xs={4} key="garden_share" className={css.mobileButtonCategory} onClick={() => onCategoryChange("garden_share")}>
                                <div>
                                    <div style={{ textAlign: "center", display: "flex", flexDirection: "column" }}>
                                        <GardenShareIcon style={{ height: 40, marginBottom: 10 }} />
                                        <FormattedMessage id={`FieldCheckbox.garden_share`} />
                                        <div className={currentCategory === "garden_share" ? css.selectedCategoryTextForMobile : css.categoryTextForMobile} >
                                        </div>
                                    </div>

                                </div>
                            </PrimaryButton>
                            <PrimaryButton xs={4} key="tinyhouse" className={css.mobileButtonCategory} onClick={() => onCategoryChange("tinyhouse")}>
                                <div>
                                    <div style={{ textAlign: "center", display: "flex", flexDirection: "column" }}>
                                        <TinyhouseIcon style={{ height: 40, marginBottom: 10 }} />
                                        <FormattedMessage id={`FieldCheckbox.tinyhouse`} />
                                        <div className={currentCategory === "tinyhouse" ? css.selectedCategoryTextForMobile : css.categoryTextForMobile} >
                                        </div>
                                    </div>

                                </div>
                            </PrimaryButton>

                        </div>
                        <Form onSubmit={() => console.log("----------SUBMT-----------")}>
                            {props => (
                                <LocationAutocompleteInputField
                                    className={css.locationRootForMobile}
                                    inputClassName={css.locationAutocompleteInput}
                                    iconClassName={css.locationAutocompleteInputIcon}
                                    predictionsClassName={css.predictionsRoot}
                                    validClassName={css.validLocation}
                                    autoFocus
                                    name="location-search"
                                    placeholder={"Où ?"}
                                    onChange={(e) => console.log(e)}
                                    useDefaultPredictions={false}
                                    format={identity}
                                    validate={(e) => handleSelectedPlace(e)}
                                />
                            )}
                        </Form>
                        <div style={{ display: "flex", justifyContent: "center", marginBottom: "10px" }}>
                            <DatePicker
                                filter={filter}
                                onChange={onChange}
                                currentCategory={currentCategory}
                                intl={intl}
                                config={config}
                                rest={rest}
                            />
                        </div>
                    </>
                }</>

            </Grid>

        </Grid >

        <Grid container spacing={!isMobile ? 5 : 0} >
            <Grid item xs={2} className={css.hideMobile}>
                <PrimaryButton className={css.categoryActive} onClick={() => onCategoryChange(currentCategory)}>
                    <FormattedMessage id={`EditListingCategoryForm.${currentCategory}`} />
                </PrimaryButton>
            </Grid>

            <Grid item xs={10} className={css.filterWrapper}>
                <Form onSubmit={() => console.log("----------SUBMT-----------")}>
                    {props => (
                        <LocationAutocompleteInputField
                            className={css.locationRoot}
                            inputClassName={css.locationAutocompleteInput}
                            iconClassName={css.locationAutocompleteInputIcon}
                            predictionsClassName={css.predictionsRoot}
                            validClassName={css.validLocation}
                            autoFocus
                            name="location-search"
                            placeholder={"Où ?"}
                            onChange={(e) => console.log(e)}
                            useDefaultPredictions={false}
                            format={identity}
                            validate={(e) => handleSelectedPlace(e)}
                        />
                    )}
                </Form>
                {
                    currentCategory === "classifields" &&
                    classifieldsOptions.map((item, i) => {
                        return <FilterButtonGroup
                            key={i}
                            title={intl.formatMessage({ id: `FieldCheckbox.${[item.key]}` })}
                            type={item.key}
                            queryName="pub_classifields_type"
                            active={filter.pub_classifields_type}
                            handleChange={onChange}
                        />
                    })
                }
                {
                    currentCategory === "garden_share" &&
                    <>
                        {gardenTypeOptions.map((item, i) => {
                            return <FilterButtonGroup
                                key={i}
                                title={intl.formatMessage({ id: `FieldCheckbox.${[item.key]}` })}
                                type={item.key}
                                queryName="pub_garden_type"
                                active={filter.pub_garden_type}
                                handleChange={onChange}
                            />
                        })
                        }
                        <Filterm2
                            history={history}
                            initValue={filter.sort}
                            handleChange={onChange}
                        />
                    </>

                }
                {
                    currentCategory === "garden_rent" || currentCategory === "tinyhouse" ?
                        <>
                            <DatePicker
                                filter={filter}
                                onChange={onChange}
                                currentCategory={currentCategory}
                                intl={intl}
                                config={config}
                                rest={rest}
                            />
                            {
                                currentCategory !== "tinyhouse" &&
                                <FilterEvent
                                    history={history}
                                    event={filter.pub_events}
                                    handleChange={onChange}
                                />
                            }
                            <FilterAmbiance
                                ambiance={filter.pub_ambiances}
                                handleChange={onChange}
                                category={urlQueryParams.pub_category}
                            />


                            <FilterMaxPeople
                                history={history}
                                maxPeople={filter.pub_capacity_max_people}
                                handleChange={onChange}
                                currentCategory={currentCategory}
                            />
                            <FilterSortBy
                                history={history}
                                initValue={filter.sort}
                                handleChange={onChange}
                            />
                        </>
                        : null
                }
                {
                    hasFilter && <p className={css.linkReset} onClick={() => onCategoryChange(currentCategory)}>Effacer les filtres</p>
                }

            </Grid>
        </Grid>

        <Grid container spacing={!isMobile ? 5 : 0}>
            <Grid item xs={1} sm={2} md={2} className={css.hideMobile}>
                {
                    categories.map((item, i) => {
                        if (item !== currentCategory) {
                            return <PrimaryButton key={i} className={css.buttonCategory} onClick={() => onCategoryChange(item)}>
                                <FormattedMessage id={`EditListingCategoryForm.${[item]}`} />
                            </PrimaryButton>
                        }
                    })
                }
            </Grid>
            <Grid item xs={12} sm={12} md={10}>
                <div style={{
                    display: "flex",
                    justifyContent: "space-between"
                }}>
                    <Button onClick={() => setOpenModal(true)} className={css.openFilterButton}>
                        Filtres
                    </Button>
                    <p style={{ marginTop: 0 }}><FormattedMessage id={`NewSearchPage.activateMap`} />
                        <PurpleSwitch
                            checked={mapModal}
                            onChange={handleMapModal}
                            name="checkedA"
                            classes={{
                                checked: css.checked
                            }}
                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                        />
                    </p>

                </div>

                {
                    searchInProgress && !mapModal ?
                        <Grid item xs={12} style={{ textAlign: "center" }}>
                            <CircularProgress style={{ color: "#e12c42" }} />
                        </Grid>
                        : props.children
                }
            </Grid>

        </Grid>

        <ModalInMobile
            containerClassName={css.modalContainer}
            id="BookingDatesFormInModal"
            isModalOpenOnMobile={openModal}
            onClose={() => setOpenModal(false)}
            protectCloseButtonBackground={true}
            showAsModalMaxWidth={MODAL_BREAKPOINT}
            onManageDisableScrolling={() => console.log("===============")}
        >

            <MobileFilter
                categories={categories}
                filter={filter}
                onChange={onChange}
                isOpen={openModal}
                urlQueryParams={urlQueryParams}
                history={history}
                setOpenModal={setOpenModal}
                currentCategory={currentCategory}
                classifieldsOptions={classifieldsOptions}
                gardenTypeOptions={gardenTypeOptions}
                intl={intl}
                config={config}
                rest={rest}
                onCategoryChange={onCategoryChange}
                locationInput={<Form onSubmit={() => console.log("----------SUBMT-----------")}>
                    {props => (
                        <LocationAutocompleteInputField
                            className={css.locationAddress}
                            inputClassName={css.locationAutocompleteInput}
                            iconClassName={css.locationAutocompleteInputIcon}
                            predictionsClassName={css.predictionsRoot}
                            validClassName={css.validLocation}
                            autoFocus
                            name="location-search"
                            placeholder={"Ou ?"}
                            useDefaultPredictions={false}
                            format={identity}
                            validate={(e) => handleSelectedPlace(e)}
                        />
                    )}
                </Form>}
            />
            {
                hasFilter && <p className={css.linkResetMobile} onClick={handleResetMobile}>Supprimer</p>
            }

        </ModalInMobile>
    </div >
}

export default NewSearchPage;
